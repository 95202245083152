document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.klaviyo-button-container').forEach((button) => {
    button.setAttribute('style', '');
  });
});

export function updateBisButtonDisplay(button, action) {
  button.classList[action]('hide');
}

